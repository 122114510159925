import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      color: { required },
      thickness: { required },
      brand: { required },
      quantity: { required }
    },
  },
  data() {
    return {
      drawerOpened: false,
      form: {
        color: null,
        thickness: null,
        brand: null,
        quantity: null,
        note: null
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
      }
    }
  },
  methods: {
    resetForm () {
      this.form.color = null
      this.form.thickness = null
      this.form.brand = null
      this.form.quantity = null
      this.form.note = null

      this.$nextTick(() => {
        this.$v.$reset();
      })
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: false
      })
    },
    async onSubmitForm() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.$emit('close-event', { 
        status: true,
        data: this.form
      })
      this.drawerOpened = false
      this.resetForm()
    }
  }
}
